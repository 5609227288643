const SERVICETYPE = {
  AIR: 'Air',
  TRANSFER: 'Transfer',
  TRAIN: 'Train',
  HOTEL: 'Hotel',
  APARTMENTS: 'Apartment',
  CUSTOM: 'Custom',
  AEROEXPRESS: 'AeroExpress',
  FLIGHT_CERTIFICATE: 'FlightCertificate',
  TAXI: 'TaxiVoucher',
  VIP_HALL: 'VipHall',
  EVENT: 'MICE',
  AIR_ADDITIONAL_SERVICE: 'AirAdditionalService',
  INSURANCE: 'Insurance',
  BUS: 'Bus',
  GROUP_HOTEL: 'GroupHotel',
};

const SERVICE_TYPE_BY_ID = [
  'Undefined',
  'Air',
  'Hotel',
  'Apartment',
  'Transfer',
  'Train',
  'Custom',
  'AeroExpress',
  'FlightCertificate',
  'TaxiVoucher',
  'TaxiSubscription',
  'VipHall',
  'MICE',
  'AirAdditionalService',
  'Insurance',
  'Bus',
];

const SERVICETYPEICON = {
  [SERVICETYPE.AIR]: 'flight',
  [SERVICETYPE.CUSTOM]: 'border_all',
  [SERVICETYPE.HOTEL]: 'hotel',
  [SERVICETYPE.TRAIN]: 'directions_railway',
  [SERVICETYPE.TRANSFER]: 'airport_shuttle',
  [SERVICETYPE.AEROEXPRESS]: 'smartway-aeroexpress-temp',
  [SERVICETYPE.FLIGHT_CERTIFICATE]: 'assignment',
  [SERVICETYPE.TAXI]: 'local_taxi',
  [SERVICETYPE.INSURANCE]: 'description',
};
const SERVICE_POLITICS_TYPE = {
  [SERVICETYPE.AIR]: 'AirlineRule',
  [SERVICETYPE.HOTEL]: 'HotelRule',
  [SERVICETYPE.TRAIN]: 'TrainRule',
  [SERVICETYPE.TRANSFER]: 'TransferVoucherRule',
  [SERVICETYPE.TAXI]: 'TaxiVoucherRule',
  [SERVICETYPE.VIP_HALL]: 'VipHallRule',
};

const SERVICETYPERU = {
  [SERVICETYPE.AIR]: 'Авиабилет',
  [SERVICETYPE.CUSTOM]: 'Кастомная услуга',
  [SERVICETYPE.HOTEL]: 'Отель',
  [SERVICETYPE.TRAIN]: 'Ж/Д билет',
  [SERVICETYPE.TRANSFER]: 'Трансфер',
  [SERVICETYPE.VIP_HALL]: 'VIP-зал',
  [SERVICETYPE.FLIGHT_CERTIFICATE]: 'Справка о совершенном перелете',
  [SERVICETYPE.AEROEXPRESS]: 'Аэроэкспресс',
  [SERVICETYPE.EVENT]: 'Мероприятие',
  [SERVICETYPE.BUS]: 'Автобусный билет',
};

export {
  SERVICETYPE as default,
  SERVICETYPE,
  SERVICE_TYPE_BY_ID,
  SERVICETYPEICON,
  SERVICETYPERU,
  SERVICE_POLITICS_TYPE,
};
